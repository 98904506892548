import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../services/firebaseService';
import { Tabs, Tab, Paper, Typography } from '@mui/material';
import CustomTable from '../CustomTable/CustomTable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const sectorPaths = [
  'الفاكهة',
  'الورد',
  'البن',
  'العسل',
  'المحاصيل البعلية',
  'الثروة الحيوانية',
  'القيمة المضافة',
  'السمك',
];

const SectorsTabs = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [sectorData, setSectorData] = useState([]);

  useEffect(() => {
    setSectorData([]);
    fetchData(`projects`, (data) => {
      data && setSectorData(data.filter((item) => item.sectorName === sectorPaths[activeTab]));
    });
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Paper elevation={3} square className="flex justify-center items-center">
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          {sectorPaths.map((sector, index) => (
            <Tab key={sector} label={sector} />
          ))}
        </Tabs>
        <div className="fixed left-5 dubai text-gray-600 ">
          <button
            onClick={() => {
              navigate('/form');
            }}>
            <AddCircleOutlineIcon />
            <span className="px-2">إضافة مشروع جديد</span>
          </button>
        </div>
      </Paper>
      <div>
        <CustomTable projects={sectorData} setSectorData={setSectorData} />

        {/* {sectorData?.map((project) => (
          <div key={project.id}>
            <Typography variant="h6">{project.projectName}</Typography>
            <Typography>{project.sectorName}</Typography>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default SectorsTabs;
