import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { createData, uploadFile, uploadOverviewImage } from '../../services/firebaseService';
import { projectSchema } from '../../shared/schemas';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFileWithProgress } from '../../store/fileUploadThunks';
import { resetInternalImageEntityState, resetState } from '../../store/fileUploadSlice';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Box, LinearProgress, Typography } from '@mui/material';
import { uploadMultipleFilesWithProgress } from '../../store/multipleFilesUploadThunk';
import { resetExternalImageEntityState } from '../../store/fileUploadSlice';
import { useNavigate } from 'react-router-dom';

type FormData = {
  id: string;
  initiatorName: string;
  city: string;
  description: string;
  sectorName: string;
  startDate: string;
  productionCapacity: number;
  employeesNo: number;
  projectArea: number;
  projectStatus: string;
  location: {
    // map: '';
    city: string;
    id: string;
    lat: string;
    lng: string;
  };
  // location: string;

  externalImageEntity: { id: number; path: string }[];
  internalImageEntity: { id: number; path: string }[];
  // formImage: FileList | null;
  // externalImageEntity: string[] | null;
  // internalImageEntity: string[] | null;
  formImage: string;
  // videos: FileList;
};

type InputNormalProps = {
  label: string;
  type: string;
  name: any;
  control: Control<FormData, any>;
  error: string | undefined;
  autoFocus?: boolean;
};

const inputClasses = `outline-none py-1.5 px-2 border rounded-md focus:border-red-200 w-full`;

const InputNormal = ({ label, name, control, type, autoFocus = false, error }: InputNormalProps) => {
  return (
    <div className="flex flex-col space-y-1.5 w-full">
      <label htmlFor={name} className="font-medium">
        {label}
      </label>
      <div>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field }) => (
            <input {...field} type={type} className={inputClasses} autoFocus={autoFocus} id={name} />
          )}
        />
      </div>
      {error && <span className="text-center text-red-400">{error}</span>}
    </div>
  );
};

const ProjectForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    setValue,
    reset,
    watch,
  } = useForm<FormData>({
    resolver: joiResolver(projectSchema),
  });

  const selectedCity = watch('city');

  const sectorPaths: string[] = [
    'الفاكهة',
    'الورد',
    'البن',
    'العسل',
    'المحاصيل البعلية',
    'الثروة الحيوانية',
    'القيمة المضافة',
    'السمك',
  ];

  const projectStatuses: string[] = ['منتظم', 'متأخر'];

  const cities: string[] = [
    'الباحة',
    'القصيم',
    'حائل',
    'عسير',
    'الشرقية',
    'جازان',
    'الرياض',
    'الطائف',
    'المدينة المنورة',
    'الجبيل',
    'حفر الباطن',
    'عرعر',
    'القنفذة',
    'شقيق',
    'أملج',
    'خور فرسان',
    'الرايس',
    'القطيف',
    'تبوك',
    'نجران',
    'فيفا (جازان)',
    'الداير (جازان)',
  ];

  const cityCoordinates = {
    الباحة: { city: 'الباحة', lat: '20.01296', lng: '41.46871' },
    القصيم: { city: 'القصيم', lat: '26.35131', lng: '44.110618' },
    حائل: { city: 'حائل', lat: '27.512377', lng: '41.727032' },
    عسير: { city: 'عسير', lat: '18.2962', lng: '42.7287' },
    الشرقية: { city: 'الشرقية', lat: '24.023498', lng: '50.12086' },
    جازان: { city: 'جازان', lat: '16.89054996', lng: '42.57440605' },
    الرياض: { city: 'الرياض', lat: '24.712357', lng: '46.670149' },
    الطائف: { city: 'الطائف', lat: '21.282786', lng: '40.420764' },
    'المدينة المنورة': { city: 'المدينة المنورة', lat: '24.39073', lng: '39.49886' },
    الجبيل: { city: 'الجبيل', lat: '24.901182', lng: '46.449338' },
    'حفر الباطن': { city: 'حفر الباطن', lat: '28.3789', lng: '45.9753' },
    عرعر: { city: 'عرعر', lat: '30.960115', lng: '41.0597319' },
    القنفذة: { city: 'القنفذة', lat: '19.12926', lng: '41.07925' },
    شقيق: { city: 'شقيق', lat: '17.71962', lng: '42.02879' },
    أملج: { city: 'أملج', lat: '25.04974', lng: '37.26468' },
    'خور فرسان': { city: 'خور فرسان', lat: '16.71232', lng: '42.18577' },
    الرايس: { city: 'الرايس', lat: '23.57557', lng: '38.60575' },
    القطيف: { city: 'القطيف', lat: '26.57438', lng: '50.00129' },
    تبوك: { city: 'تبوك', lat: '28.3856872', lng: '36.5707123' },
    نجران: { city: 'نجران', lat: '17.56519', lng: '44.22925' },
    'فيفا (جازان)': { city: 'فيفا (جازان)', lat: '17.24741', lng: '43.10759' },
    'الداير (جازان)': { city: 'الداير (جازان)', lat: '16.8896', lng: '42.5539' },
  };

  const [isLatModified, setIsLatModified] = useState(false);
  const [isLngModified, setIsLngModified] = useState(false);

  useEffect(() => {
    if (selectedCity && cityCoordinates[selectedCity as keyof typeof cityCoordinates]) {
      const { city, lat, lng } = cityCoordinates[selectedCity as keyof typeof cityCoordinates];

      // Only reset values if the user hasn't manually modified them
      if (!isLatModified) {
        setValue('location.lat', lat); // Set default latitude
      }
      if (!isLngModified) {
        setValue('location.lng', lng); // Set default longitude
      }
      setValue('location.city', city); // Set default city
    }
  }, [selectedCity, isLatModified, isLngModified]);

  const handleLngChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLngModified(true);
    setValue('location.lng', e.target.value);
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLatModified(true);
    setValue('location.lat', e.target.value);
  };

  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  const {
    progress,
    url,
    error,
    externalImageEntityProgress,
    externalImageEntityUrl,
    externalImageEntityError,
    internalImageEntityProgress,
    internalImageEntityUrl,
    internalImageEntityError,
  } = useSelector((state: RootState) => state.fileUpload);

  useEffect(() => {
    if (url) {
      setValue('formImage', url);
    }
  }, [url]);
  useEffect(() => {
    // Cleanup state when the component unmounts
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const uploadNewFile = (newFile: any) => {
    if (newFile) {
      dispatch(uploadFileWithProgress({ newFile }));
    }
  };

  const handleOverviewChange = async (fieldName: keyof FormData, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      uploadNewFile(file);
    }
  };

  //////////
  useEffect(() => {
    if (externalImageEntityUrl) {
      // handle success, e.g., add the file to the state
      setValue('externalImageEntity', externalImageEntityUrl);
    }
  }, [externalImageEntityUrl]);
  useEffect(() => {
    if (internalImageEntityUrl) {
      // handle success, e.g., add the file to the state
      setValue('internalImageEntity', internalImageEntityUrl);
    }
  }, [internalImageEntityUrl]);
  useEffect(() => {
    // Cleanup state when the component unmounts
    return () => {
      dispatch(resetExternalImageEntityState());
      dispatch(resetInternalImageEntityState());
    };
  }, [dispatch]);

  const uploadNewMultipleFiles = (newFiles: any, imgType: string) => {
    if (newFiles) {
      if (imgType === 'external') {
        dispatch(uploadMultipleFilesWithProgress({ newFiles: newFiles, imgType: imgType }));
      } else {
        dispatch(uploadMultipleFilesWithProgress({ newFiles: newFiles, imgType: imgType }));
      }
    }
  };
  // Handle file input changes
  const handleFileChange = async (fieldName: keyof FormData, e: React.ChangeEvent<HTMLInputElement>) => {
    const filesBeforeUpload = e.target.files;

    if (filesBeforeUpload) {
      if (fieldName === 'externalImageEntity') {
        // setNewExternalFile(filesBeforeUpload);
        uploadNewMultipleFiles(filesBeforeUpload, 'external');
      } else {
        // setNewInternalFile(filesBeforeUpload);
        uploadNewMultipleFiles(filesBeforeUpload, 'internal');
      }
    }
  };

  const navigate = useNavigate();

  const onSubmit = (data: FieldValues) => {
    setIsLatModified(false);
    setIsLngModified(false);
    data = {
      ...data,
      id: Math.floor(Math.random() * 1000000),
      // location: {
      //   // map: '',
      //   city: '',
      //   id: '',
      //   lat: '',
      //   lng: '',
      // },
      projectStatus: data.projectStatus || null,
      startDate: data.startDate || null,
      externalImageEntity: data.externalImageEntity || null,
      internalImageEntity: data.internalImageEntity || null,
    };

    createData('projects', data);

    navigate(`/`);

    // You can send the data to your backend or perform other actions here
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <section className="pt-14">
      <header>
        <h3 className="text-2xl font-bold text-center">إضافة مشروع</h3>
      </header>
      <main>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
            reset();
            dispatch(resetState());
            dispatch(resetExternalImageEntityState());
            dispatch(resetInternalImageEntityState());
          })}
          className="p-10"
          encType="multipart/form-data">
          <section className="grid w-full grid-cols-2 gap-5">
            <InputNormal
              label="اسم المشروع"
              name="initiatorName"
              control={control}
              type="text"
              error={errors.initiatorName?.message}
              autoFocus
            />
            <div className="flex flex-col space-y-1.5 w-full">
              <label htmlFor="city" className="font-medium">
                اسم المدينة
              </label>
              <div>
                <Controller
                  name="city"
                  // defaultValue={cities[0]}
                  control={control}
                  render={({ field }) => (
                    <select {...field} className={`${inputClasses}`} id="city">
                      <option value="" disabled={watch('city') ? true : false} className="text-white">
                        اختر
                      </option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              {errors.city?.message && <span className="text-center text-red-400">{errors.city?.message}</span>}
            </div>
            {selectedCity && (
              <>
                <div className="flex flex-col space-y-1.5 w-full">
                  <label htmlFor="location.lng" className="font-medium">
                    خطوط الطول
                  </label>
                  <div>
                    <Controller
                      name="location.lng"
                      defaultValue={watch('location.lng')}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className={inputClasses}
                          id="location.lng"
                          name='"location.lng"'
                          onChange={handleLngChange}
                        />
                      )}
                    />
                  </div>
                  {errors?.location?.lng?.message && (
                    <span className="text-center text-red-400">{errors?.location?.lng?.message}</span>
                  )}                </div>
                <div className="flex flex-col space-y-1.5 w-full">
                  <label htmlFor="location.lat" className="font-medium">
                    دوائر العرض
                  </label>
                  <div>
                    <Controller
                      name="location.lat"
                      defaultValue={watch('location.lat')}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className={inputClasses}
                          id="location.lat"
                          name='"location.lat"'
                          onChange={handleLatChange}
                        />
                      )}
                    />
                  </div>
                  {errors?.location?.lat?.message && (
                    <span className="text-center text-red-400">{errors?.location?.lat?.message}</span>
                  )}
                </div>
                {/* <InputNormal
                  label="خطوط الطول"
                  name="location.lng"
                  control={control}
                  type="text"
                  error={errors.location?.lat?.message}
                  onChange={handleLatChange} // Handle latitude changes
                /> */}
                {/* <InputNormal
                  label="دوائر العرض"
                  name="location.lat"
                  control={control}
                  type="text"
                  error={errors.location?.lng?.message}
                  onChange={handleLngChange} // Handle longitude changes
                /> */}
              </>
            )}
            {/* <InputNormal label="اسم المدينة" name="city" control={control} type="text" error={errors.city?.message} /> */}
            <InputNormal
              label="وصف المشروع"
              name="description"
              control={control}
              type="text"
              error={errors.description?.message}
            />

            <div className="flex flex-col space-y-1.5 w-full">
              <label htmlFor="sectorName" className="font-medium">
                اسم القطاع
              </label>
              <div>
                <Controller
                  name="sectorName"
                  // defaultValue={sectorPaths[0]}
                  control={control}
                  render={({ field }) => (
                    <select {...field} className={`${inputClasses}`} id="sectorName">
                      <option value="" disabled={watch('sectorName') ? true : false} className="text-white">
                        اختر
                      </option>
                      {sectorPaths.map((sector, index) => (
                        <option key={index} value={sector}>
                          {sector}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              {errors.sectorName?.message && (
                <span className="text-center text-red-400">{errors.sectorName?.message}</span>
              )}
            </div>

            <div className="flex flex-col space-y-1.5 w-full">
              <label htmlFor="startDate" className="font-medium">
                بداية التشغيل
              </label>
              <div>
                <Controller
                  name="startDate"
                  control={control}
                  // defaultValue={getTodayDate()}
                  render={({ field }) => (
                    <input
                      type="date"
                      {...field}
                      className={`${inputClasses}`}
                      id="startDate"
                      placeholder="YYYY-MM-DD"
                    />
                  )}
                />
              </div>
              {errors.startDate?.message && (
                <span className="text-center text-red-400">{errors.startDate?.message}</span>
              )}
            </div>

            <InputNormal
              label="الطاقة الانتاجية"
              name="productionCapacity"
              control={control}
              type="number"
              error={errors.productionCapacity?.message}
            />

            <InputNormal
              label="عدد العمالة"
              name="employeesNo"
              control={control}
              type="number"
              error={errors.employeesNo?.message}
            />

            <InputNormal
              label="مساحة المشروع"
              name="projectArea"
              control={control}
              type="number"
              error={errors.projectArea?.message}
            />

            <div className="flex flex-col space-y-1.5 w-full">
              <label htmlFor="projectStatus" className="font-medium">
                حالة المشروع
              </label>
              <div>
                <Controller
                  name="projectStatus"
                  // defaultValue={projectStatuses[0]}
                  control={control}
                  render={({ field }) => (
                    <select {...field} className={`${inputClasses}`} id="projectStatus">
                      <option value="" disabled={watch('projectStatus') ? true : false} className="text-white">
                        اختر
                      </option>
                      {projectStatuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              {errors.projectStatus?.message && (
                <span className="text-center text-red-400">{errors.projectStatus?.message}</span>
              )}
            </div>
            {/* <InputNormal
              label="اسم القطاع"
              name="sectorName"
              control={control}
              type="text"
              error={errors.sectorName?.message}
            /> */}
            {/* <InputNormal
              label="الموقع الجغرافي"
              name="location.map"
              control={control}
              type="text"
              error={errors.location?.map?.message}
            /> */}
            {/* <InputNormal
              label="المدينة"
              name="location.city"
              control={control}
              type="text"
              error={errors.location?.city?.message}
            />
            <InputNormal
              label="خطوط الطول"
              name="location.lng"
              control={control}
              type="text"
              error={errors.location?.lat?.message}
            />
            <InputNormal
              label="خطوط العرض"
              name="location.lat"
              control={control}
              type="text"
              error={errors.location?.lng?.message}
            /> */}

            <div className="flex flex-col space-y-1.5 w-full">
              <label className="font-medium">الصور الخارجية</label>
              <Controller
                name="externalImageEntity"
                control={control}
                render={({ field, fieldState }) => (
                  <input
                    type="file"
                    onChange={(e) => handleFileChange('externalImageEntity', e)}
                    accept="image/*"
                    multiple
                    className={inputClasses}
                  />
                )}
              />
              {externalImageEntityProgress > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={externalImageEntityProgress} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      externalImageEntityProgress,
                    )}%`}</Typography>
                  </Box>
                </Box>
              )}
              {errors.externalImageEntity && externalImageEntityProgress !== 100 && (
                <span className="text-center text-red-400">{errors.externalImageEntity?.message}</span>
              )}
            </div>

            <div className="flex flex-col space-y-1.5 w-full">
              <label className="font-medium">الصور الداخلية</label>
              <Controller
                name="internalImageEntity"
                control={control}
                render={({ field, fieldState }) => (
                  <input
                    type="file"
                    // {...field}
                    onChange={(e) => handleFileChange('internalImageEntity', e)}
                    accept="image/*"
                    multiple
                    className={inputClasses}
                  />
                )}
              />
              {internalImageEntityProgress > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={internalImageEntityProgress} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      internalImageEntityProgress,
                    )}%`}</Typography>
                  </Box>
                </Box>
              )}
              {errors.internalImageEntity && internalImageEntityProgress !== 100 && (
                <span className="text-center text-red-400">{errors.internalImageEntity?.message}</span>
              )}
            </div>

            <div className="flex flex-col space-y-1.5 w-full">
              <label className="font-medium">صورة نموذج المشروع</label>
              <Controller
                name="formImage"
                control={control}
                // defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <input
                    type="file"
                    onChange={(e) => handleOverviewChange('formImage', e)}
                    accept="image/*"
                    className={inputClasses}
                  />
                )}
              />
              {progress > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
                  </Box>
                </Box>
              )}
              {errors.formImage && progress !== 100 && (
                <span className="text-center text-red-400">{errors.formImage?.message}</span>
              )}
            </div>
          </section>
          <button
            type="submit"
            // disabled={progress < 100 || externalImageEntityProgress < 100 || internalImageEntityProgress < 100}
            className="outline-none border px-3 py-1.5 rounded-md mt-5">
            إضافة مشروع
          </button>
        </form>
      </main>
    </section>
  );
};

export default ProjectForm;

// if (fieldName === "videos") {
//   // For videos, we need to handle the files differently (e.g., upload to Firebase storage)
//   const videoFiles = filesBeforeUpload && (await uploadVideoFiles(filesBeforeUpload));

//   // Update the form value with the array of video URLs or other relevant data
//   setValue(fieldName, videoFiles);
// } else {
//   // For other file types (externalImageEntity, internalImageEntity, formImage), handle as before
//   const files = filesBeforeUpload && (await uploadFile(filesBeforeUpload));

//   setValue(fieldName, files!);
// }

/* <div>
        <label>Videos</label>
        <Controller
          name="videos"
          control={control}
          render={({ field }) => (
            <input type="file" onChange={(e) => handleFileChange('videos', e)} accept="video/*" multiple />
          )}
        />
  </div> */
