import { DataSnapshot, get, push, ref, remove, set, update } from 'firebase/database';
import { db } from '../firebaseConfig';

// import { Storage } from "../firebaseConfig";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
// Function to read data from the database
// export const fetchData = async (path: string, callback: (data: any) => void) => {
//   const dataRef = ref(db, path);

//   try {
//     const snapshot: DataSnapshot = await get(dataRef);
//     const data = snapshot.val();
//     const arrayOfObjects =
//       data &&
//       Object.keys(data).map((key) => ({
//         ...data[key],
//         id: key,
//       }));

//     callback(arrayOfObjects);
//   } catch (error) {
//     console.error('Error fetching data:', error);
//   }
// };

export const fetchData = async (path: string, callback: (data: any) => void) => {
  const dataRef = ref(db, path);

  try {
    const snapshot: DataSnapshot = await get(dataRef);
    const data = snapshot.val();

    const arrayOfObjects =
      data &&
      Object.keys(data)
        .filter((key) => !data[key].deleted) // Filter out deleted items
        .map((key) => ({
          ...data[key],
          id: key,
        }));

    callback(arrayOfObjects);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchDataById = async (path: string, callback: (data: any) => void) => {
  const dataRef = ref(db, path);

  try {
    const snapshot: DataSnapshot = await get(dataRef);
    const data = snapshot.val();
    // const arrayOfObjects =
    //   data &&
    //   Object.keys(data).map((key) => ({
    //     ...data[key],
    //     id: key,
    //   }));

    callback(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// const dataRef = ref(db, `projects/${newData?.sectorName}`);
//   const newItemRef = push(dataRef, newData);
//   const dataKey = newItemRef?.key;

// Function to create data in the database
export const createData = (path: string, newData: any) => {
  const dataRef = ref(db, path);

  const newRef = push(dataRef); // Generate a new reference with a unique ID
  const newKey = newRef.key; // Get the generated ID
  set(newRef, newData)
    .then(() => {
      console.log('Data created successfully.');
      return newKey;
    })
    .catch((error) => {
      console.error('Error creating data:', error);
    });
};

// Function to update data in the database
export const updateData = (path: string, updatedData: any) => {
  const dataRef = ref(db, path);
  update(dataRef, updatedData)
    .then(() => {
      console.log('Data updated successfully.');
    })
    .catch((error) => {
      console.error('Error updating data:', error);
    });
};

// Function to delete data in the database
export const deleteData = (path: string) => {
  const dataRef = ref(db, path);

  return remove(dataRef)
    .then(() => {
      console.log('Data deleted successfully.');
    })
    .catch((error) => {
      console.error('Error deleting data:', error);
    });
};

export const uploadFile = async (files: FileList) => {
  const imageUrls = [];

  try {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storage = getStorage();
      const storageReference = storageRef(storage, `CPanel-3D-web/files/${file.name}`);
      const snapshot = await uploadBytes(storageReference, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      imageUrls.push({ id: i, path: downloadURL });
    }
    return imageUrls;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const uploadOverviewImage = async (files: FileList) => {
  const imageUrls = [];

  try {
    const file = files[0];
    const storage = getStorage();
    const storageReference = storageRef(storage, `CPanel-3D-web/files/${file.name}`);
    const snapshot = await uploadBytes(storageReference, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    imageUrls.push(downloadURL);
    return imageUrls[0];
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};
