import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import SectorsTabs from './SectorsTabs/SectorsTabs';
// import { RTL } from "@mui/material/Unstable";

const ProjectList = () => {
  // const [projects, setProjects] = useState<ProjectType[]>([]);
  // const [loading, setLoading] = useState(true);
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      {/* <RTL> */}
      <CssBaseline />
      <SectorsTabs />
      {/* </RTL> */}
    </ThemeProvider>
  );
};

export default ProjectList;
