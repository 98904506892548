import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <main id="main" dir="rtl">
      <Outlet />
    </main>
  );
};

export default Layout;
