// fileUploadThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  setExternalImageEntityProgress,
  setExternalImageEntityUrl,
  setExternalImageEntityError,
  setInternalImageEntityProgress,
  setInternalImageEntityUrl,
  setInternalImageEntityError,
} from './fileUploadSlice';

interface UploadEditFileWithProgressArgs {
  newFile: File;
  edit?: boolean;
  imgType?: string;
  images: { id: number; path: string }[];
  imageId?: number;
}

export const uploadEditFileWithProgress = createAsyncThunk(
  'fileUpload/uploadEditFileWithProgress',
  async ({ newFile, edit, imgType, images, imageId }: UploadEditFileWithProgressArgs, { dispatch }) => {
    try {

      const storage = getStorage();
      const fileRef = storageRef(storage, `C-panel/files/${newFile?.name}`);

      const uploadTask = uploadBytesResumable(fileRef, newFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (imgType === 'external') {
            dispatch(setExternalImageEntityProgress(progress));
          } else {
            dispatch(setInternalImageEntityProgress(progress));
          }

          switch (snapshot.state) {
            case 'paused':
            //   console.log('Upload is paused');
              break;
            case 'running':
            //   console.log('Upload is running');
              break;
          }
        },
        (error) => {
          if (imgType === 'external') {
            dispatch(setExternalImageEntityError(error.message));
          } else {
            dispatch(setInternalImageEntityError(error.message));
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
            // dispatch(setUrl(downloadURL));
            const updatedArray: { id: number; path: string }[] = images.map((obj) =>
              obj.id === imageId ? { ...obj, path: downloadURL || '' } : obj,
            );

            if (imgType === 'external') {
              dispatch(setExternalImageEntityUrl(updatedArray));
            } else {
              dispatch(setInternalImageEntityUrl(updatedArray));
            }
          });
        },
      );
    } catch (error: any) {
      if (imgType === 'external') {
        dispatch(setExternalImageEntityError(error.message));
      } else {
        dispatch(setInternalImageEntityError(error.message));
      }
    }
  },
);
