import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ImageGrid from '../ImageViewer/ImageGrid';

type ImageViewerPopupProps = {
  open: boolean;
  onClose: () => void;
  images: { path: string; id: number }[] | string;
};

const ImageViewerPopup = ({ open, onClose, images }: ImageViewerPopupProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Image Viewer</DialogTitle>
      <DialogContent>
        {/* <ImageSlider images={images} /> */}
        <ImageGrid images={images} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageViewerPopup;
