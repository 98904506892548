import { signInWithEmailAndPassword } from 'firebase/auth';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import Joi, { boolean } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import loginImage from '../../assets/login-background.ceaefcd8.jpg';
import leftArrow from '../../assets/left-arrow.png';
import { Box, Paper } from '@mui/material';
import { useState } from 'react';
const schema = Joi.object({
  username: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'يجب إدخال اسم المستخدم',
      'string.empty': 'اسم المستخدم غير صحيح',
      'string.email': 'يجب إدخال بريد إلكتروني صحيح',
    }),
  password: Joi.string().min(6).required().messages({
    'any.required': 'يجب إدخال الرقم السري',
    'string.empty': 'الرقم السري غير صحيح',
    'string.min': 'يجب أن يتكون الرقم السري من 6 أحرف على الأقل',
  }),
});

interface IUser {
  username: string;
  password: string;
}

const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUser>({
    resolver: joiResolver(schema),
  });

  // console.log(errors, 'all errors');
  const navigate = useNavigate();

  const onSubmit = async ({ username, password }: FieldValues) => {
    // console.log('Username: ', username);
    // console.log('Password: ', password);
    try {
      await signInWithEmailAndPassword(auth, username, password);

      navigate('/');
    } catch (error) {
      console.log('Failed to login');
    }
  };

  const style: Record<string, React.CSSProperties> = {
    enterBtn: {
      background: 'transparent',
      cursor: 'pointer',
      color: '#fff',
      fontSize: '25px',
    },
    enter: {
      position: 'absolute',
      top: ' 88%',
      right: '5%',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      width: '10%',
      border: '3px solid #fff',
      textAlign: 'center',
      justifyContent: 'center',
      borderRadius: '28px',
      cursor: 'pointer',
      zIndex: 111,
    },
    '@keyframes slideTop': {
      '0%': {
        transform: 'translateY(0)',
      },
      '100%': {
        transform: 'translateY(-100px)',
      },
    } as React.CSSProperties,
    loginForm: {
      display: 'block',
      position: 'absolute',
      animationName: 'slideTop',
      animationDuration: '3s',
      animationTimingFunction: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      animationFillMode: 'both',
      padding: '10px 15px',
      width: '450px',
      height: '300px',
      borderRadius: '27px',
      right: '75px',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      boxShadow: '#d9d6cd 1px 1px 3px',
      transition: 'all 1s ease-in-out',
      bottom: '200px',
    } as React.CSSProperties,
    displayNone: {
      display: 'none',
    } as React.CSSProperties,
    inputStyle: {
      width: '100%',
      padding: '0px 0px',
      margin: '10px 0px 10px 0',
      borderRadius: '25px',
    } as React.CSSProperties,
    placeholderStyle: {
      color: '#fff',
    } as React.CSSProperties,
    focusedInput: {
      outline: '#fff',
      borderColor: '#fff',
    } as React.CSSProperties,
    signinButton: {
      border: '1px solid #fff',
      backgroundColor: 'transparent',
      borderRadius: '25px',
      padding: '16.5px 14px',
      marginTop: '20px',
      color: '#fff',
      height: '5px',
    } as React.CSSProperties,
    heightInput: {
      height: '5px',
    } as React.CSSProperties,
  };

  // const style = {
  //   enterBtn: {
  //     background: "transparent",
  //     cursor: "pointer",
  //     color: "#fff",
  //     fontSize: "25px",
  //   }as React.CSSProperties,
  //   enter: {
  //     position: 'absolute',
  //     top: ' 88%',
  //     right: '5%',
  //     display: 'flex',
  //     alignContent: 'center',
  //     alignItems: 'center',
  //     width: '10%',
  //     border: '3px solid #fff',
  //     textAlign: 'center',
  //     justifyContent: 'center',
  //     borderRadius: '28px',
  //     cursor: 'pointer',
  //     zIndex: '111',
  //   } as React.CSSProperties,
  //   "@keyframes slideTop": {
  //     "0%": {
  //       transform: "translateY(0)",
  //     },
  //     "100%": {
  //       transform: "translateY(-100px)",
  //     },
  //   }as React.CSSProperties,

  //   loginForm: {
  //     display: "block",
  //     position: "absolute",
  //     animationName: "slideTop",
  //     animationDuration: "3s",
  //     animationTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  //     animationFillMode: "both",
  //     // animation: "slideTop 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both",
  //     padding: "10px 15px",
  //     width: "450px",
  //     height: "300px",
  //     borderRadius: "27px",
  //     right: "75px",
  //     // bottom: "0px",
  //     backgroundColor: " rgba(0, 0, 0, 0.4)",
  //     boxShadow: "#d9d6cd 1px 1px 3px",
  //     transition: "all 1s ease-in-out",
  //     bottom: "200px",
  //   }as React.CSSProperties,

  //   displayNone: {
  //     display: "none",
  //   }as React.CSSProperties,
  //   inputStyle: {
  //     width: "100%",
  //     padding: "0px 0px",
  //     margin: "10px 0px 10px 0",
  //     borderRadius: "25px",
  //   }as React.CSSProperties,
  //   placeholderStyle: {
  //     color: "#fff",
  //   }as React.CSSProperties,
  //   focusedInput: {
  //     outline: "#fff",
  //     borderColor: "#fff",
  //   }as React.CSSProperties,
  //   signinButton: {
  //     border: "1px solid #fff",
  //     backgroundColor: "transparent",
  //     borderRadius: "25px",
  //     padding: "16.5px 14px",
  //     marginTop: "20px",
  //     color: "#fff",
  //     height: "5px",
  //   }as React.CSSProperties,
  //   heightInput: {
  //     height: "5px",
  //   }as React.CSSProperties,
  // };

  const [isShown, setIsShown] = useState<boolean>(false);

  const handleShown = () => {
    setIsShown(!isShown);
  };

  return (
    <Box className="flex-auto h-full md:flex" sx={{ backgroundImage: `url(${loginImage})`,height:"100vh" }}>
      <Paper style={isShown ? style.loginForm : style.displayNone}>
        <section className="p-5">
          <header>
            <h3 className="text-center">أهلاً بكم..</h3>
          </header>
          <main className="flex flex-col items-center">
            <form
              className="flex flex-col items-center w-1/2 space-y-4"
              onSubmit={handleSubmit((data) => {
                onSubmit(data);
                reset();
              })}>
              <div className="flex flex-col w-full space-y-2">
                <label htmlFor="username" className="">
                  اسم المستخدم
                </label>
                <input
                  {...register('username')}
                  autoFocus
                  type="text"
                  name="username"
                  id="username"
                  className="w-full p-1.5 rounded-md border outline-none"
                />
                {errors.username?.message && <span className="text-red-400 ">{errors.username?.message}</span>}
              </div>
              <div className="flex flex-col w-full space-y-2">
                <label htmlFor="password" className="">
                  كلمة المرور
                </label>
                <input
                  {...register('password')}
                  type="password"
                  name="password"
                  id="password"
                  className="w-full p-1.5 rounded-md border outline-none"
                />
                {errors.password?.message && <span className="text-red-400 ">{errors.password?.message}</span>}
              </div>
              <button type="submit" className="py-1.5 px-3 rounded-md bg-slate-200">
                تسجيل الدخول
              </button>
            </form>
          </main>
        </section>
      </Paper>

        <div
        style={style.enter}
          // className="absolute top-[80%] right-[5%] flex items-center align-middle w-[10%] text-center justify-center cursor-pointer z-[111] border-2 border-[#fff] rounded-md"
          onClick={() => handleShown()}>
          <input type="button" value="الدخول"
           style={style.enterBtn}
          //  className="bg-transparent	 cursor-pointer text-[#fff] text-[25px]" 
           />
          <img src={leftArrow} alt="left-arrow" />
        </div>

    </Box>
  );
};

export default Login;
