import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { deleteData } from '../../services/firebaseService';
import ImageViewerPopup from '../ImageViewerPopup/ImageViewerPopup';
import Swal from 'sweetalert2';

type Project = {
  id: string;
  initiatorName: string;
  city: string;
  description: string;
  sectorName: string;
  startDate: string;
  productionCapacity: number;
  employeesNo: number;
  projectArea: number;
  projectStatus: string;
  externalImageEntity: { path: string; id: number }[]; // Assuming images is an array of image URLs
  internalImageEntity: { path: string; id: number }[];
  location: {
    city: string;
    id: string;
    lat: string;
    lng: string;
    map: string;
  };
  // location: string;
  formImage: string;
};

type TableProps = {
  projects: Project[];
  setSectorData: any;
};

const CustomTable = ({ projects, setSectorData }: TableProps) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [imagesToView, setImagesToView] = useState<{ path: string; id: number }[]>([{ path: '', id: 0 }]);
  const [imageType, setImageType] = useState('');
  const [imageToView, setImageToView] = useState<string>('');
  const navigate = useNavigate();

  const openImageViewer = (images: { path: string; id: number }[]) => {
    setImagesToView(images);
    setViewerOpen(true);
    setImageType('');
  };

  const openOverviewImageViewer = (image: string) => {
    setImageType('overview');
    setImageToView(image);
    setViewerOpen(true);
  };

  const editProject = (projectId: string, sectorName: string) => {

    navigate(`/projects/${sectorName}/${projectId}/update`);
  };

  const deleteProject = (projectName: string, projectId: string, sectorName: string) => {
    // 
    Swal.fire({
      // icon: 'warning',
      title: ` هل تريد تأكيد حذف مشروع`,
      text: `${projectName}`,
      confirmButtonColor: '#52b3d9',
      confirmButtonText: 'حذف',
      showDenyButton: true, // Enable the second button
      denyButtonColor: '#ff6347',
      denyButtonText: 'إلغاء', // Set custom text for the second button
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the confirmation button click
        // You can put your logic here
        deleteData(`projects/${projectId}`)
          .then(() => {
            // Deletion successful, update the UI
            // Filter out the deleted project from the projects array
            const updatedProjects = projects.filter((project) => project.id !== projectId);
            setSectorData(updatedProjects);
          })
          .catch((error: any) => {
            console.error('Error deleting project:', error);
          });
        // 
      } else if (result.isDenied) {
        // Handle the second button click
        // You can put your logic here
        // 
      }
    });
  };


  const styles: { [key: string]: React.CSSProperties } = {
    customFont: {
      textAlign: 'center',
      fontFamily: 'dubai',
    },
    customLocationFont: {
      textAlign: 'center',
      fontFamily: 'dubai',
      color: '#1976d2',
    },
  };

  const formatDate = (dateString: any) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formNumbers = (oldNumber: number) => {
    const suffixes = ['H', 'K', 'M', 'Bn'];
  
    let newTotal: number = oldNumber;
    const orderOfMagnitude = Math.min(
      Math.floor(Math.log10(newTotal) / 3),
      suffixes.length - 1
    );
  
    let newNumberFormatted =
      (newTotal / Math.pow(1000, orderOfMagnitude)).toFixed(1) +
      suffixes[orderOfMagnitude];
  
    console.log(oldNumber);
    console.log(newNumberFormatted);
    console.log(suffixes[orderOfMagnitude]);
  
    return newNumberFormatted;
  };
  

  return (
    <TableContainer component={Paper}>
      <Table style={{ fontFamily: 'dubai', fontSize: '25px' }}>
        <TableHead>
          <TableRow>
            <TableCell style={styles.customFont}>اسم المشروع</TableCell>
            <TableCell style={styles.customFont}>اسم المدينة</TableCell>
            <TableCell style={styles.customFont}>وصف المشروع</TableCell>
            <TableCell style={styles.customFont}>اسم القطاع</TableCell>
            <TableCell style={styles.customFont}>بداية التشغيل</TableCell>
            <TableCell style={styles.customFont}>الطاقة الانتاجية</TableCell>
            <TableCell style={styles.customFont}>عدد العمالة</TableCell>
            <TableCell style={styles.customFont}>مساحة المشروع</TableCell>
            <TableCell style={styles.customFont}>حالة المشروع</TableCell>
            {/* <TableCell style={styles.customFont}>الموقع الجغرافي</TableCell> */}
            <TableCell style={styles.customFont}>الصور الداخلية</TableCell>
            <TableCell style={styles.customFont}>الصور الخارجية</TableCell>
            <TableCell style={styles.customFont}>صورة نموذج المشروع</TableCell>
            <TableCell style={styles.customFont}>
              {/* <button
                onClick={() => {
                  navigate('/form');
                }}>
                إضافة مشروع جديد
              </button> */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.length !== 0 ? (
            projects?.map((project, index) => (
              <TableRow style={styles.customFont} key={index}>
                <TableCell style={styles.customFont}>{project.initiatorName}</TableCell>
                <TableCell style={styles.customFont}>{project.city}</TableCell>
                <TableCell style={styles.customFont}>{project.description ? project.description : '__'}</TableCell>
                <TableCell style={styles.customFont}>{project.sectorName}</TableCell>
                <TableCell style={styles.customFont}>
                  {project.startDate ? formatDate(project.startDate) : '__'}
                </TableCell>
                <TableCell style={styles.customFont}>
                  {project.productionCapacity ? formNumbers(project.productionCapacity) : '__'}
                </TableCell>
                <TableCell style={styles.customFont}>{project.employeesNo ? formNumbers(project.employeesNo) : '__'}</TableCell>
                <TableCell style={styles.customFont}>{project.projectArea ? formNumbers(project.projectArea) : '__'}</TableCell>
                <TableCell style={styles.customFont}>
                  {project.projectStatus ? (
                    <span>
                      <span className='ml-1'>
                      {project.projectStatus === 'منتظم' ? <CircleIcon style={{fontSize:"10px",color: '#329b3c'}} /> : <CircleIcon style={{fontSize:"10px",color: '#f00'}}/>}
                      </span>
                      {project.projectStatus}
                    </span>
                  ) : (
                    '__'
                  )}
                </TableCell>

                {/* <TableCell style={styles.customFont}>
                  {project.location?.city} {project.location.lng ? <>&deg;{project.location.lng}</> : ''}{' '}
                  {project.location.lat ? <>&deg;{project.location.lat}</> : ''}
                </TableCell> */}
                {/* <TableCell style={styles.customLocationFont}>
                  {project.location?.city} {project.location.lng ? <>&deg;{project.location.lng}</> : ''}{' '}
                  {project.location.lat ? <>&deg;{project.location.lat}</> : ''}
                  {project.location.map && (

                  <a href={project.location.map} target='blank'>الموقع في الخريطة</a>
                  )}
                </TableCell> */}
                <TableCell style={styles.customFont}>
                  <button
                    className="bg-[#16a085] p-2 font-bold border-none rounded w-[110px] text-white cursor-pointer"
                    onClick={() => openImageViewer(project.internalImageEntity)}>
                    عرض الصور
                  </button>
                </TableCell>
                <TableCell style={styles.customFont}>
                  <button
                    className="bg-[#16a085] p-2 font-bold border-none rounded w-[110px] text-white cursor-pointer"
                    onClick={() => openImageViewer(project.externalImageEntity)}>
                    عرض الصور
                  </button>
                </TableCell>
                <TableCell style={styles.customFont}>
                  <button
                    className="bg-[#16a085] p-2 font-bold border-none rounded w-[110px] text-white cursor-pointer"
                    onClick={() => openOverviewImageViewer(project.formImage)}>
                    عرض الصورة
                  </button>
                </TableCell>
                <TableCell style={styles.customFont}>
                  <button
                    className="p-2 mx-1 font-bold border-none rounded w-[50px] text-white cursor-pointer bg-[#52b3d9]"
                    // style={{ ...styles.editButton, ...styles.actionButtons }}
                    onClick={() => editProject(project.id, project.sectorName)}>
                    تعديل
                  </button>
                  <button
                    className="p-2 mx-1 font-bold border-none rounded w-[50px] text-white cursor-pointer bg-[#ff6347] opacity-80"
                    onClick={() => deleteProject(project.initiatorName, project.id, project.sectorName)}>
                    حذف
                  </button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>لا يوجد مشاريع بعد</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ImageViewerPopup
        images={imageType === 'overview' ? imageToView : imagesToView}
        open={viewerOpen}
        onClose={() => setViewerOpen(false)}
      />
    </TableContainer>
  );
};

export default CustomTable;
