type ImageGridProps = {
  images: { path: string; id: number }[] | string;
};

const ImageGrid = ({ images }: ImageGridProps) => {
  return (
    <>
      {images ? (
        <div
          className={
            typeof images === 'object' ? 'grid grid-rows-3 grid-cols-3 gap-4' : 'flex justify-center items-center'
          }>
          {typeof images === 'object' ? (
            images.map((image, index) => (
              <div className="col-span-1 row-span-1" key={index}>
                <img src={image.path} alt={`Image ${index}`} width="500px" height="150px" />
              </div>
            ))
          ) : (
            <img src={images} alt={`Image`} width="500px" />
          )}
        </div>
      ) : (
        <div className="text-xl font-bold text-center">
          <p>لا يوجد صور لعرضها</p>
        </div>
      )}
    </>
  );
};

export default ImageGrid;
