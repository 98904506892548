// fileUploadSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FileUploadState {
  progress: number;
  url: string | null;
  error: string | null;
  externalImageEntityProgress: number;
  externalImageEntityUrl: { id: number; path: string }[] | null;
  externalImageEntityError: string | null;
  internalImageEntityProgress: number;
  internalImageEntityUrl: { id: number; path: string }[] | null;
  internalImageEntityError: string | null;
}

const initialState: FileUploadState = {
  progress: 0,
  url: null,
  error: null,
  externalImageEntityProgress: 0,
  externalImageEntityUrl: null,
  externalImageEntityError: null,
  internalImageEntityProgress: 0,
  internalImageEntityUrl: null,
  internalImageEntityError: null,
};

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetState: (state) => {
      state.progress = 0;
      state.url = null;
      state.error = null;
    },
    ////// external images
    setExternalImageEntityProgress: (state, action: PayloadAction<number>) => {
      state.externalImageEntityProgress = action.payload;
    },
    setExternalImageEntityUrl: (state, action: PayloadAction<{ id: number; path: string }[]>) => {
      state.externalImageEntityUrl = action.payload;
    },
    setExternalImageEntityError: (state, action: PayloadAction<string>) => {
      state.externalImageEntityError = action.payload;
    },
    resetExternalImageEntityState: (state) => {
      state.externalImageEntityProgress = 0;
      state.externalImageEntityUrl = null;
      state.externalImageEntityError = null;
    },
    ////// internal images
    setInternalImageEntityProgress: (state, action: PayloadAction<number>) => {
      state.internalImageEntityProgress = action.payload;
    },
    setInternalImageEntityUrl: (state, action: PayloadAction<{ id: number; path: string }[]>) => {
      state.internalImageEntityUrl = action.payload;
    },
    setInternalImageEntityError: (state, action: PayloadAction<string>) => {
      state.internalImageEntityError = action.payload;
    },
    resetInternalImageEntityState: (state) => {
      state.internalImageEntityProgress = 0;
      state.internalImageEntityUrl = null;
      state.internalImageEntityError = null;
    },
  },
});

export const {
  reducer: fileUploadReducer,
  actions: {
    setProgress,
    setUrl,
    setError,
    resetState,
    setExternalImageEntityProgress,
    setExternalImageEntityUrl,
    setExternalImageEntityError,
    resetExternalImageEntityState,
    setInternalImageEntityProgress,
    setInternalImageEntityUrl,
    setInternalImageEntityError,
    resetInternalImageEntityState,
  },
} = fileUploadSlice;
