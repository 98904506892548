import { createUserWithEmailAndPassword } from 'firebase/auth';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';

interface IUser {
  username: string;
  password: string;
}

const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUser>();

  const navigate = useNavigate();

  const onSubmit = async ({ username, password }: FieldValues) => {
    // console.log('Username: ', username);
    // console.log('Password: ', password);

    try {
      await createUserWithEmailAndPassword(auth, username, password);

      navigate('/');
    } catch (error) {
      console.log('Failed to register.');
    }
  };

  return (
    <section className="p-5">
      <header>
        <h3 className="text-center">أهلاً بكم..</h3>
      </header>
      <main className="flex flex-col items-center">
        <form
          className="flex flex-col space-y-4 w-1/2 items-center"
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
            reset();
          })}>
          <div className="flex flex-col space-y-2 w-full">
            <label htmlFor="username" className="">
              اسم المستخدم
            </label>
            <input
              {...register('username')}
              autoFocus
              type="text"
              name="username"
              id="username"
              className="w-full p-1.5 rounded-md border outline-none"
            />
            {errors.username?.message && <div>{errors.username?.message}</div>}
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <label htmlFor="password" className="">
              كلمة المرور
            </label>
            <input
              {...register('password')}
              type="password"
              name="password"
              id="password"
              className="w-full p-1.5 rounded-md border outline-none"
            />
            {errors.username?.message && <div>{errors.username?.message}</div>}
          </div>
          <button type="submit" className="py-1.5 px-3 rounded-md bg-slate-200">
            إضافة حساب جديد
          </button>
        </form>
      </main>
    </section>
  );
};

export default Register;
