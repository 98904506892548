// fileUploadThunks.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { setProgress, setUrl, setError } from "./fileUploadSlice";

interface UploadFileWithProgressArgs {
  newFile: File;
}

export const uploadFileWithProgress = createAsyncThunk(
  "fileUpload/uploadFileWithProgress",
  async ({ newFile }: UploadFileWithProgressArgs, { dispatch }) => {
    try {
      const storage = getStorage();
      const fileRef = storageRef(
        storage,
        `C-panel/files/${newFile?.name}`
      );

      const uploadTask = uploadBytesResumable(fileRef, newFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          dispatch(setProgress(progress));

          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          dispatch(setError(error.message));
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL:any) => {

            dispatch(setUrl(downloadURL));
          });
        }
      );
    } catch (error:any) {
      dispatch(setError(error.message));
    }
  }
);
