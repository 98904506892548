import { createBrowserRouter } from 'react-router-dom';
import { EditProjectDetails, ProtectedRoute } from '../components';
import ProjectForm from '../components/Form/ProjectForm';
import { AuthProvider } from '../contexts/AuthProvider';
import Home from '../pages/Home';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Layout from './Layout';
import ImageUpload from '../components/Form/ImageUpload';
import CityForm from '../components/Form/CityForm';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      // <AuthProvider>
      <Layout />
      // </AuthProvider>
    ),
    children: [
      {
        index: true,
        element: (
          // <ProtectedRoute>
          <Home />
          // </ProtectedRoute>
        ),
      },
      // {
      //   path: 'add-city-test',
      //   element: (
      //     // <ProtectedRoute>
      //     <CityForm />
      //     // </ProtectedRoute>
      //   ),
      // },
      {
        path: '/form',
        element: (
          // <ProtectedRoute>
          <ProjectForm />
          // </ProtectedRoute>
        ),
      },
      {
        path: '/projects/:sectorName/:id/update',
        element: (
          // <ProtectedRoute>
          <EditProjectDetails />
          // </ProtectedRoute>
        ),
      },
      { path: '/login', element: <Login /> },
      { path: '/register', element: <Register /> },
      { path: '/test', element: <ImageUpload /> },
    ],
  },
]);

export default router;
