// fileUploadThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  setExternalImageEntityProgress,
  setExternalImageEntityUrl,
  setExternalImageEntityError,
  resetExternalImageEntityState,
  setInternalImageEntityProgress,
  setInternalImageEntityUrl,
  setInternalImageEntityError,
} from './fileUploadSlice';

interface UploadMultipleFilesWithProgressArgs {
  newFiles: FileList;
  imgType: string;
}

export const uploadMultipleFilesWithProgress = createAsyncThunk(
  'fileUpload/uploadMultipleFilesWithProgress',
  async ({ newFiles, imgType }: UploadMultipleFilesWithProgressArgs, { dispatch }) => {
    try {
      if (imgType === 'external') {
        const storage = getStorage();
        const imageUrls: { id: number; path: string }[] = [];

        // Calculate progress for each file
        const progressPerFile = 100 / newFiles.length;

        // Create a promise for each file
        const uploadPromises = Array.from(newFiles).map((file, i) => {
          return new Promise<void>(async (resolve, reject) => {
            const storageReference = storageRef(storage, `CPanel-3D-web/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageReference, file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (i + snapshot.bytesTransferred / snapshot.totalBytes) * progressPerFile;
                dispatch(setExternalImageEntityProgress(progress));
              },
              (error) => {
                reject(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                imageUrls.push({ id: i, path: downloadURL });

                // Resolve the promise when all uploads are complete
                if (imageUrls.length === newFiles.length) {
                  dispatch(setExternalImageEntityProgress(100));
                  dispatch(setExternalImageEntityUrl(imageUrls));
                  resolve();
                }
              },
            );
          });
        });

        // Wait for all promises to resolve
        await Promise.all(uploadPromises);
      } else {
        const storage = getStorage();
        const imageUrls: { id: number; path: string }[] = [];

        // Calculate progress for each file
        const progressPerFile = 100 / newFiles.length;

        // Create a promise for each file
        const uploadPromises = Array.from(newFiles).map((file, i) => {
          return new Promise<void>(async (resolve, reject) => {
            const storageReference = storageRef(storage, `CPanel-3D-web/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageReference, file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (i + snapshot.bytesTransferred / snapshot.totalBytes) * progressPerFile;
                dispatch(setInternalImageEntityProgress(progress));
              },
              (error) => {
                reject(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                imageUrls.push({ id: i, path: downloadURL });

                // Resolve the promise when all uploads are complete
                if (imageUrls.length === newFiles.length) {
                  dispatch(setInternalImageEntityProgress(100));
                  dispatch(setInternalImageEntityUrl(imageUrls));
                  resolve();
                }
              },
            );
          });
        });

        // Wait for all promises to resolve
        await Promise.all(uploadPromises);
      }
    } catch (error: any) {
      // Handle errors and dispatch appropriate actions
      // console.error('Error uploading files:', error);
      if (imgType === 'external') {
        dispatch(setInternalImageEntityError(error.message));
      } else {
        dispatch(setExternalImageEntityError(error.message));
      }
    }
  },
);
