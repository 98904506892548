import { configureStore } from '@reduxjs/toolkit';
import { fileUploadReducer } from './fileUploadSlice';

const store = configureStore({
  reducer: {
    fileUpload: fileUploadReducer,
    // Add other reducers here if you have more slices
  },
});

// Define RootState
export type RootState = ReturnType<typeof store.getState>;

export default store;
