// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBm8abTyxAuDqjtM_NefJOkjKRiujVeLcE',
  authDomain: 'evara-44379.firebaseapp.com',
  databaseURL: 'https://evara-3d.firebaseio.com/',
  projectId: 'evara-44379',
  storageBucket: 'evara-44379.appspot.com',
  messagingSenderId: '116675819706',
  appId: '1:116675819706:web:e9219625d9e1ce0c592a83',
  measurementId: 'G-M6CZ1RQYQ8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const auth = getAuth(app);

// export const Storage = getStorage(app);
export { db, auth };
