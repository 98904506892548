import Joi from 'joi';

export const projectSchema = Joi.object({
  id: Joi.optional(),
  initiatorName: Joi.string().min(5).required().messages({
    'string.empty': 'يجب إدخال اسم المشروع',
    'string.min': 'يجب أن يكون اسم المشروع على الأقل 5 أحرف',
  }),
  city: Joi.string().min(2).required().messages({
    'string.empty': 'يجب إدخال اسم المدينة',
    'any.required': 'يجب اختيار المدينة',
  }),
  description: Joi.string().allow(null, '').messages({
    // description: Joi.string().required().messages({
    'string.empty': 'يجب إدخال وصف المشروع',
  }),
  sectorName: Joi.string().min(3).required().messages({
    'string.empty': 'sector name is required',
    'any.required': 'يجب اختيار القطاع',
  }),
  startDate: Joi.date().iso().allow(null, '').messages({
    // startDate: Joi.date().required().iso().messages({
    // 'date.base': 'Start date must be a valid date',
    // 'date.empty': 'يجب إدخال تاريخ بدابة التشغيل',
    // 'any.required': 'يجب إدخال تاريخ بدابة التشغيل',
    'date.format': 'يجب ان تكون بداية التشغيل بصيغه (DD-MM-YYYY) ',
  }),
  // startingDate: Joi.date().min(3).required().messages({
  //   'date.empty': 'sector name is required',
  // }),
  productionCapacity: Joi.number().integer().allow(null, '').messages({
    // productionCapacity: Joi.number().integer().required().messages({
    'number.base': 'يجب ادخال الطاقة الانتاجية كرقم',
    'number.integer': 'يجب ادخال الطاقة الانتاجية كعدد صحيح',
  }),
  employeesNo: Joi.number().integer().allow(null, '').messages({
    // productionCapacity: Joi.number().integer().required().messages({
    'number.base': 'يجب ادخال عدد العمالة كرقم',
    'number.integer': 'يجب ادخال عدد العمالة كعدد صحيح',
  }),
  projectArea: Joi.number().integer().allow(null, '').messages({
    'number.base': 'يجب ادخال مساحة المشروع كرقم',
    'number.integer': 'يجب ادخال مساحة المشروع كعدد صحيح',
  }),
  projectStatus: Joi.allow(null).messages({
    'any.required': 'يجب ادخال حالة المشروع',
  }),
  location: Joi.object({
    // map: Joi.string().pattern(
    //   /^https:\/\/maps\.app\.goo\.gl\/[a-zA-Z0-9_-]+$/,
    //   { name: 'Google Maps link', invert: false }
    // )
    // .messages({
    //   'any.required': 'الموقع مطلوب',
    //   'string.empty': 'الموقع مطلوب',
    //   'string.pattern.name': 'يجب أن يكون الرابط من خرائط جوجل',
    // }),
    city: Joi.string().messages({
      'any.required': 'المدينة مطلوبة',
      'string.empty': 'المدينة مطلوبة',
    }),
    lng: Joi.number().messages({
      'number.base': 'خطوط الطول يجب أن تكون أرقاماً فقط',
      'any.required': 'خطوط الطول مطلوبة',
    }),
    //id: Joi.string().required(),
    lat: Joi.number().messages({
      'number.base': 'دوائر العرض يجب أن تكون أرقاماً فقط',
      'any.required': 'دوائر العرض مطلوبة',
    }),
  }),
  externalImageEntity: Joi.array().allow(null, '').messages({
    'any.required': 'الصور الخارجية لنموذج المشروع مطلوبة',
    'string.empty': 'يجب أن يكون هناك ملف لصورة نموذج المشروع',
    // Add more specific error messages as needed
  }),
  internalImageEntity: Joi.array().allow(null, '').messages({
    'any.required': 'الصور الداخلية لنموذج المشروع مطلوبة',
    'string.empty': 'يجب أن يكون هناك ملف لصورة نموذج المشروع',
    // Add more specific error messages as needed
  }),
  formImage: Joi.string().required().messages({
    'any.required': 'صورة نموذج المشروع مطلوبة',
    'string.empty': 'يجب أن يكون هناك ملف لصورة نموذج المشروع',
    // Add more specific error messages as needed
  }),
  // videos: Joi.array().label("Videos"),
});
